<template>
  <AccountLayout>
    <a-card class="account-left-container">
      <AccountIntroLayout />
    </a-card>
    <a-card hoverable class="account-right-container">
      <div class="login-container">
        <div class="login-container-header">现在注册,即可免费体验</div>
        <phone-captcha-form
          @sendCaptcha="doVerifyPhoneCaptcha"
          @submit="registerPhoneWithCaptcha"
          :formLoading="formLoading"
        ></phone-captcha-form>
      </div>
    </a-card>
    <template slot="outside">
      <Verify
        @success="registerPhoneSendCaptcha"
        @error="verifyErrorHandler"
        :mode="'pop'"
        :captchaType="'blockPuzzle'"
        :imgSize="{ width: '330px', height: '155px' }"
        ref="phoneCaptchaVerify"
      ></Verify>
    </template>
  </AccountLayout>
</template>



<script>
import Verify from "@/components/verifition";
import PhoneCaptchaForm from "./components/phone-captcha-form";
import { loginAPI } from "@/api/login";
import { deepCopy } from "@/libs/utils";
import md5 from "md5";
export default {
  name: "login",
  props: {
    msg: String,
  },
  data() {
    return {
      formFields: {},
      formLoading: false,
    };
  },
  components: {
    Verify,
    PhoneCaptchaForm,
  },
  mounted() {
    let service = this.$route.query.service;
    if (service) {
      this.service = service;
    }
  },
  methods: {
    doVerifyPhoneCaptcha(data) {
      this.$refs.phoneCaptchaVerify.show();
      this.formFields = data;
    },
    verifyErrorHandler(res) {
      this.$message.error(res.repMsg + ",错误码:[" + res.repCode + "]");
    },
    analyzerLoginRes(res) {
      if (res.status) {
        var accessToken = res.data;
        if(this.service.indexOf('?') > -1) {
          window.location.href =
              this.service + "&" + "accessToken=" + accessToken;
        } else {
          window.location.href =
              this.service + "?" + "accessToken=" + accessToken;
        }
      } else {
        this.$message.error(res.msg + ",错误码:[" + res.code + "]");
      }
    },
    loginWithPwd(verifyParams) {
      this.formLoading = true;
      let request = deepCopy(this.formFields);
      request.authCaptcha = verifyParams.captchaVerification;
      request.password = md5(this.formFields.password);
      loginAPI
        .loginWithPwd(request)
        .then((res) => {
          this.analyzerLoginRes(res);
          this.formLoading = false;
        })
        .catch((e) => {
          this.$message.error("调用失败", e);
          this.formLoading = false;
        });
    },
    registerPhoneSendCaptcha(verifyParams) {
      this.formFields.authCaptcha = verifyParams.captchaVerification;
      loginAPI
        .registerPhoneSendCaptcha(this.formFields)
        .then((res) => {
          if (!res.status) {
            this.$message.error(res.msg + ",错误码:[" + res.code + "]");
          }
        })
        .catch((res) => {
          if (!res.status) {
            this.$message.error(res.msg + ",错误码:[" + res.code + "]");
          }
        });
    },
    registerPhoneWithCaptcha(data) {
      this.formLoading = true;
      let request = deepCopy(data);
      request.authCaptcha = this.formFields.authCaptcha;
      loginAPI
        .registerPhoneWithCaptcha(request)
        .then((res) => {
          this.analyzerLoginRes(res);
          this.formLoading = false;
        })
        .catch((e) => {
          this.$message.error("调用失败", e);
          this.formLoading = false;
        });
    },
    switchLoginMode() {
      this.loginMode = this.loginMode === 1 ? 2 : 1;
    },
  },
};
</script>
