<template>
  <a-form-model
    :hideRequiredMark="true"
    :model="model"
    :rules="rules"
    :wrapper-col="{ span: 24 }"
    ref="form"
    class="login-container-form"
  >
    <a-row :gutter="24">
      <a-col :span="8">
        <a-form-model-item prop="areaCode">
          <a-select
            v-model="model.areaCode"
            v-if="areaCodes"
            :defaultValue="model.areaCode"
            size="large"
          >
            <a-select-option
              :value="areaCode.area_code"
              v-for="areaCode in areaCodes"
              :key="areaCode.area_code"
            >
              {{ areaCode.area }} {{ areaCode.area_code }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-col>
      <a-col :span="16">
        <a-form-model-item prop="accountName">
          <a-input
            size="large"
            v-model="model.accountName"
            placeholder="请输入手机号"
          >
          </a-input>
        </a-form-model-item>
      </a-col>
    </a-row>
    <a-form-model-item prop="captcha">
      <a-input size="large" v-model="model.captcha" placeholder="请输入验证码">
        <template slot="suffix">
          <a-button type="link" @click="sendCaptcha">获取验证码</a-button>
        </template>
      </a-input>
    </a-form-model-item>
    <a-form-model-item prop="rememberMe" style="float: right">
      <a-checkbox v-model="model.rememberMe"> 3天内自动登录 </a-checkbox>
    </a-form-model-item>
    <a-form-model-item>
      <a-button
        type="primary"
        size="large"
        block
        :loading="formLoading"
        @click="doSubmit"
      >
        注册即可开通免费试用
      </a-button>
    </a-form-model-item>
    <a-form-model-item prop="agree" style="float: left">
      <a-checkbox v-model="model.agree"> 阅读并同意 </a-checkbox>
      <a-button type="link" style="padding: 0px" @click="goPrivacyPolicy">
        《用户隐私协议》
      </a-button>
    </a-form-model-item>
    <a-form-model-item style="float: right">
      已有帐号?
      <a-button type="link" @click="goLogin"> 登录 </a-button>
    </a-form-model-item>
  </a-form-model>
</template>
<script>
import { RegExpHelper } from "@/libs/regexp";
import { FormMixin } from "@/views/mixins/form";
export default {
  name: "login-phone-captcha-form-model",
  props: {
    formLoading: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [FormMixin],
  data() {
    return {
      model: {
        areaCode: "+86",
        accountName: "",
        captcha: "",
        rememberMe: false,
        agree: true,
      },
      rules: {
        accountName: [
          {
            required: true,
            message: "手机号不能为空",
            trigger: "blur",
          },
          {
            validator: (rule, value, callback) => {
              if (!RegExpHelper.phone.test(value)) {
                callback(new Error("请输入正确的手机号"));
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
        captcha: [
          {
            required: true,
            message: "验证码不能为空",
            trigger: "blur",
          },
          {
            validator: (rule, value, callback) => {
              if (!RegExpHelper.captcha.test(value)) {
                callback(new Error("验证码必须为6位数字"));
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
        agree: [
          {
            validator: (rule, value, callback) => {
              if (!value) {
                callback(new Error("请勾选同意协议内容"));
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {},
};
</script>